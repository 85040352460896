import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';

import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { catchError, filter, mapTo, switchMap, takeUntil, tap } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from '../../auth/auth.service';
import { LoggingService } from '../../_logging/logging.service';
import { getErrorMessage } from '../../_shared/shared.functions';
import { AppConfigService } from '../../app-config.service';
import { ReAuthDialogComponent } from '../../_shared/re-auth-dialog/re-auth-dialog.component';
import { DialogWidthConfig } from '../../_shared/dialog-error/dialog-error.component';

@Injectable({
    providedIn: 'root',
})
export class ReAuthService {
    public readonly sessionVerificationIntervalInSeconds = 1000 * 60;
    private reAuthInterval: Observable<number>;
    private reset$: Subject<void> = new Subject<void>();

    private dialogRef: MatDialogRef<ReAuthDialogComponent>;
    private isDialogOpenEmitter$ = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private log: LoggingService,
        private appConfigService: AppConfigService,
        private dialog: MatDialog,
        private locationStrategy: LocationStrategy,
    ) {}

    public get isDialogOpened(): boolean {
        return this.isDialogOpenEmitter$.getValue();
    }

    public get isDialogOpen$(): Observable<any> {
        return this.isDialogOpenEmitter$.asObservable();
    }

    public initReAuth(): void {
        if (!this.authService.isLoggedIn()) {
            return;
        }
        this.authService
            .getReAuthData()
            .pipe(
                filter(({ reAuthTimeoutEnabled }) => reAuthTimeoutEnabled),
                tap(() => this.setupReAuthDelay()),
                switchMap((data) => this.verifyAuthExpire().pipe(mapTo(data))),
            )
            .subscribe({
                error: (error) => {
                    this.log.error('Cant init client re-auth settings: ' + getErrorMessage(error));
                },
            });
    }

    public endReAuthCheck(): void {
        this.reset$.next();
    }

    public setupReAuthDelay(): void {
        this.reAuthInterval = interval(this.sessionVerificationIntervalInSeconds);

        this.reAuthInterval
            .pipe(
                filter(() => !this.dialogRef),
                switchMap(() => this.verifyAuthExpire()),
                takeUntil(this.reset$),
            )
            .subscribe();
    }

    private verifyAuthExpire(): Observable<void> {
        return this.isAuthExpired().pipe(
            filter((isExpired) => isExpired),
            tap(() => this.showReAuthModal()),
            mapTo(void 0),
        );
    }

    private isAuthExpired(): Observable<boolean> {
        return this.authService.isUISessionExpired().pipe(
            catchError((error) => {
                this.log.error('Cant verify user re-auth => ' + getErrorMessage(error));
                return of(false);
            }),
        );
    }

    private showReAuthModal(): void {
        if (this.dialogRef) {
            return;
        }
        this.isDialogOpenEmitter$.next(true);
        this.dialogRef = this.dialog.open(ReAuthDialogComponent, {
            width: DialogWidthConfig.MEDIUM,
            disableClose: true,
            backdropClass: 'blurred-backdrop',
        });

        this.dialogRef.afterClosed().subscribe(() => {
            this.dialogRef = null;
            this.isDialogOpenEmitter$.next(false);
        });
    }

    private observePopState(): void {
        this.locationStrategy.onPopState(() => this.verifyAuthExpire().subscribe());
    }
}
