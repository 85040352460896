import { ChangeDetectionStrategy, Component } from '@angular/core';

import { timer } from 'rxjs';

import { ITooltipAngularComp } from 'ag-grid-angular';
import { Column, ITooltipParams } from 'ag-grid-enterprise';

import tippy, { Content, Instance } from 'tippy.js';

export type ITippyTooltipParams = ITooltipParams & { tooltipValue: string };

@Component({
    selector: 'trds-cell-tippy-tooltip',
    template: '',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellTippyTooltipComponent implements ITooltipAngularComp {
    agInit(params: ITippyTooltipParams): void {
        params.hideTooltipCallback();

        if (!params.value?.length) {
            return;
        }

        if (!params.tooltipValue?.length) {
            params.tooltipValue = params.value;
        }

        this.createTippyTooltipAtCellPosition(params);
    }

    private createTippyTooltipAtCellPosition(params: ITippyTooltipParams): void {
        const rowEl: Element = this.getElementBySelector(`[row-index="${params.rowIndex}"]`);
        if (rowEl) {
            const cellEl: Element = this.getElementBySelector(`[col-id="${(params.column as Column).getColId()}"]`);

            if (cellEl) {
                this.showTippyTooltip(this.createTippyTooltip(cellEl, params.tooltipValue));
            }
        }
    }

    private getElementBySelector(selector: string): Element | undefined {
        const nodeList: NodeListOf<Element> = document.querySelectorAll(selector);
        return nodeList?.length === 1 ? nodeList.item(0) : null;
    }

    private createTippyTooltip(element: Element, content: Content): Instance {
        return tippy(element, {
            content,
            delay: [200, 50],
            theme: 'trds-black-theme'
        });
    }

    private showTippyTooltip(instance: Instance): void {
        timer(0).subscribe({
            complete: () => instance.show()
        });
    }
}
