import { UserSettingsKeys } from 'src/app/_shared/shared.models';
import { MarkerClassType, SidebarPanelType, TimePeriod, TimeRangeOptions } from '../enums/market-inspection.enum';
import { ClickSpecification, MarkerForm, MarkerType } from './chart.models';
import { IEventsSearchParams, ISearchCriteria, ISearchQueryParams } from '../components/tables/table.model';
import { MiEventsIntervalStatisticResponse } from './events-aggregation.models';
import { LegendStatus } from '../enums/events.enum';
import { SymbolPair } from '../../ucm/cases/models';
import { ICreateImageDto } from './events.models';
import { ElementRef } from '@angular/core';

// This DTO is sent from the backend.
export interface ISymbolPairsExchangesDTO {
    symbols: SymbolPairExchanges[];
    exchanges: ExchangeMap;
}

export interface ISymbolPairsExchangesDTO_V2 {
    symbols: SymbolPairExchanges[];
    exchanges: ExchangeMap[];
}

export interface PrivateSymbolsDto {
    spot: string;
    derivatives: string;
}

export interface PrivateSymbols {
    spot: string[];
    derivatives: string[];
}

export type SymbolPairExchanges = {
    [key: string]: SecondSymbolExchanges[];
};

type SecondSymbolExchanges = {
    [key: string]: number[];
};
export type ExchangeMap = {
    [key: number]: string;
};

export type SymbolPairExchangesMap = {
    [key: string]: string[];
};

export interface ISymbolPairsExchanges {
    symbolsPairsList: string[];
    symbolPairExchangesMap: SymbolPairExchangesMap;
    privateSymbols?: PrivateSymbols;
}

export type SymbolExchangeFilter = {
    symbols: string[];
    exchanges: string[];
};

export type Filter = {
    period: Period;
    fromTime: number;
    toTime: number;
    symbolsPair: [
        {
            fromSymbol: string;
            toSymbol: string;
        }
    ];
    exchanges: string[];
};

export type Period = {
    quantity: number;
    unit: CandlePeriodUnit;
};

export enum CandlePeriodUnit {
    MILLISECOND = 'MILLISECOND',
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY'
}

export enum PackageType {
    STANDARD = 'STANDARD',
    PREMIUM = 'PREMIUM',
    REGULATOR = 'REGULATOR'
}

export enum TradeCategory {
    Spot = 'Spot',
    Derivatives = 'Derivatives'
}

export interface IUserFilterState {
    privateData: boolean;
    clientPackageType: PackageType;
    state: {
        symbols: string[];
        exchanges: string[];
        isCandleView: boolean;
        isDerivatives: boolean;
        fromTime: number;
        toTime: number;
        selectedPeriod?: TimePeriod;
        priceChartInfo: IPriceChartInfo;
    };
}

export enum MIColors {
    UpVolume = '#98b788',
    DownVolume = '#c35946',
    SelectedUpVolume = '#6eb44a',
    SelectedDownVolume = '#f84121',
    ClientDefaultMarkerColor = '#cad5db',
    FirstChart = '#AE90C2',
    SecondChart = '#D38248',
    ThirdChart = '#7FAEE0',
    highlightOutline = 'white',
    BuyColor = '#BADDA8',
    SellColor = '#FF735A',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    MidPrice = '#7FAEE0',
    BuyColor_Darker = '#729F56',
    SellColor_Darker = '#a62d2d',
    Assent500 = '#FFCD26',
    TextColor = 'black',
    secondarySymbolLine1 = '#0CFFFF',
    secondarySymbolLine2 = '#93B8BF',
    secondarySymbolLine3 = '#50A9FF',
    secondarySymbolLine4 = '#FB87C6',
    secondarySymbolLine5 = '#E0FF65',
    TextColorWhite = '#FFFFFF',
    TimeSpanAreaColor = '#FFA500',
    ChartBackground = '#181818',
    TradeMarker = '#C1E7EE',
    MarkerBackgroundColor = '#2C2D2E'
}

export const TimePeriodOptions: { [key: string]: Period } = {
    [TimePeriod.ms_1]: { quantity: 1, unit: CandlePeriodUnit.MILLISECOND },
    [TimePeriod.ms_10]: { quantity: 10, unit: CandlePeriodUnit.MILLISECOND },
    [TimePeriod.ms_100]: { quantity: 100, unit: CandlePeriodUnit.MILLISECOND },
    [TimePeriod.sec_1]: { quantity: 1, unit: CandlePeriodUnit.SECOND },
    [TimePeriod.sec_10]: { quantity: 10, unit: CandlePeriodUnit.SECOND },
    [TimePeriod.min_1]: { quantity: 1, unit: CandlePeriodUnit.MINUTE },
    [TimePeriod.min_10]: { quantity: 10, unit: CandlePeriodUnit.MINUTE },
    [TimePeriod.hour_1]: { quantity: 1, unit: CandlePeriodUnit.HOUR },
    [TimePeriod.hour_6]: { quantity: 6, unit: CandlePeriodUnit.HOUR },
    [TimePeriod.day_1]: { quantity: 1, unit: CandlePeriodUnit.DAY }
};

export const OneSecondMilliseconds = 1000;
export const OneDayMilliseconds = OneSecondMilliseconds * 60 * 60 * 24;
export const OneMinuteMilliseconds = OneSecondMilliseconds * 60;
export const OneHourMilliseconds = OneSecondMilliseconds * 60 * 60;
export const HalfHourMilliseconds = OneSecondMilliseconds * 60 * 30;

export const candleUnitsMs: { [key: string]: number } = {
    [CandlePeriodUnit.MILLISECOND]: 1,
    [CandlePeriodUnit.SECOND]: OneSecondMilliseconds,
    [CandlePeriodUnit.MINUTE]: OneMinuteMilliseconds,
    [CandlePeriodUnit.HOUR]: OneHourMilliseconds,
    [CandlePeriodUnit.DAY]: OneDayMilliseconds
};

export const fromTimeByRange: { [key: string]: (from?: number) => number } = {
    [TimeRangeOptions.min_30]: (from = Date.now()) => from - HalfHourMilliseconds,
    [TimeRangeOptions.day_1]: (from = Date.now()) => from - OneDayMilliseconds,
    [TimeRangeOptions.day_5]: (from = Date.now()) => from - 5 * OneDayMilliseconds,
    [TimeRangeOptions.month_1]: (from = Date.now()) => from - 30 * OneDayMilliseconds,
    [TimeRangeOptions.month_3]: (from = Date.now()) => from - 90 * OneDayMilliseconds,
    [TimeRangeOptions.month_6]: (from = Date.now()) => from - 183 * OneDayMilliseconds,
    [TimeRangeOptions.year_1]: (from = Date.now()) => from - 365 * OneDayMilliseconds
};

export const timePeriodMs: { [key: string]: number } = {
    [TimePeriod.ms_1]: 1,
    [TimePeriod.ms_10]: 10,
    [TimePeriod.ms_100]: 100,
    [TimePeriod.sec_1]: OneSecondMilliseconds,
    [TimePeriod.sec_10]: 10 * OneSecondMilliseconds,
    [TimePeriod.min_1]: OneMinuteMilliseconds,
    [TimePeriod.min_10]: 10 * OneMinuteMilliseconds,
    [TimePeriod.hour_1]: 60 * OneMinuteMilliseconds,
    [TimePeriod.hour_6]: OneDayMilliseconds / 4,
    [TimePeriod.day_1]: OneDayMilliseconds
};

export interface IOrderExecutionDTO {
    id?: string;
    version: number;
    transactionTime: string;
    price: number;
    cumQty: number;
    orderQty: number;
    leavesQty: number;
    side: string;
    symbol: string;
    orderStatus: string;
    orderType: string;
    clientId: string;
    executionVenue: string;
    actorId: string;
    account: string;
    localCurrency: string;
    localNotional: number;
    eventId: string;
}

export interface ITablePageResponse {
    items: IOrderExecutionDTO[];
    pageNumber: number;
    pageSize: number;
    total: number;
}

export interface ISymbolsPair {
    fromSymbol: string;
    toSymbol: string;
}

export interface IMarketInspectionQueryBody {
    periodMin: number;
    fromTime: number;
    toTime: number;
    symbolsPair: MISymbolPair[];
    exchanges: string[];
}

export type MISymbolPair = {
    first: string;
    second: string;
};

export interface QuoteFeed {
    fetchInitialData: (symbol, startDate, endDate, params, cb) => void;
    fetchPaginationData: (symbol, startDate, endDate, params, cb) => void;
    fetchUpdateData?: (symbol, startDate, params, cb) => void;
}

export interface ICSVRecords {
    [key: string]: string | number | null;
}

export interface IQueryParam {
    [key: string]: number | string;
}

// export enum MIEventType {
//     ORDER = 'ORDER',
//     EXECUTION = 'EXECUTION'
// }

// export enum MIAlert {
//     ALERT = 'ALERT'
// }

export enum MITableType {
    EVENT = 'EVENT',
    ALERT = 'ALERT'
}

export const TableTabsTitle = {
    [MITableType.EVENT]: 'Events',
    [MITableType.ALERT]: 'Alerts'
};

export const MI_CSV_DATA = 'market_inspection_csv_data';
export const MI_ALERTS_QUERY_PARAM = 'alert';
export const MI_CSV_EVENTS_DATA = 'market_inspection_csv_events_data';
const MI_ALERTS_KEY = 'market_inspection_alert_data';

export const getMiAlertsKey = (alertId: string): string => `${MI_ALERTS_KEY}_${alertId}`;

export interface BenchmarkConfig {
    key: BenchMarkPrimaryKeys;
    checked: boolean;
    config: {
        [key: string]: {
            name: string;
            color: string;
            lineWidth: number;
            lineType: string;
        };
    };
}

export interface IBenchmarkConfig {
    benchmarkConfig: BenchmarkConfig[];
    exchange: string;
    sendRequest: boolean;
    saveState: boolean;
    isUserEmitted: boolean;
}

export enum BenchMarkPrimaryKeys {
    BBO = 'bbo',
    MID_PRICE = 'midPrice'
}

export enum BenchMarkForeignKeys {
    BEST_BID = 'bestBid',
    BEST_ASK = 'bestAsk',
    MID_PRICE = 'midPrice'
}

export const ChartSeries = {
    BestBid: 'Best Bid',
    BestAsk: 'Best Ask',
    MidPrice: 'Mid Price',
    ClientVolumes: 'PRIVATE VOLUME'
};

export type BenchMarkKey = BenchMarkPrimaryKeys.BBO | BenchMarkPrimaryKeys.MID_PRICE;

export interface IMarketDataBody {
    pairs: SymbolPairIntervals[];
}

export interface SymbolPairIntervals {
    name: string;
    intervals: IMarketInterval[];
}

export interface IMarketInterval {
    date: string;
    exchanges: IExchangeIntervalData[];
}

export interface IExchangeIntervalData {
    exchange: string;
    open: number;
    close: number;
    low: number;
    high: number;
    volume: number;
    attributedVolume?: number;
    attributedNumTrades?: number;
    numTrades: number;
}

// Market inspection benchmarks data from the server
export interface IBenchmarkResponseBody {
    pairs: IBenchmarkSymbolPairIntervals[];
}

export interface IBenchmarkSymbolPairIntervals {
    name: string;
    intervals: IBenchmarkInterval[];
}

export interface IBenchmarkInterval {
    date: string;
    benchmarks: IBenchmark;
}

export interface IBenchmark {
    bestBid?: number;
    bestAsk?: number;
}

export interface IChartInterval {
    DT?: Date;
    Date?: string;
    Close?: number;
    Open?: number;
    High?: number;
    Low?: number;
    Volume?: number;

    [key: string]: SeriesItem | number | unknown;

    BestBid?: SeriesItem;
    BastAsk?: SeriesItem;
    MidPrice?: SeriesItem;
}

export interface ChartMarkerClickObject {
    markerId: string;
    type: MarkerType;
    isSelected: boolean;
}

export enum ChartClickType {
    CANDLE = 'CANDLE',
    MARKER = 'MARKER',
    PERF_MARKER = 'PERF_MARKER'
}

export interface ChartClickObject {
    type: ChartClickType;
    item: IChartInterval | ChartMarkerClickObject;
    symbol: string;
    clickedEvent: ClickedEvent;
    clickFromTable: boolean;
    status: LegendStatus | string;
}

export interface ClickedEvent {
    useEventAggregation: boolean;
    singleEvent?: SingleEvent;
    aggregationMarker?: AggregationMarker;
}

export interface SingleEvent {
    eventTs: number;
    status: string;
    id: string;
    clientId: string;
    symbol: string;
}

export interface MarkerConfig {
    id: string;
    date: Date;
    symbol: string;
    price: number;
    status?: string[];
    side: string;
    clientId: string;
    useEventAggregation: boolean;
    aggregationMarkerFromDate?: Date;
    imageNode?: ElementRef;
}

export interface AggregationMarker {
    fromTime: number;
    toTime?: number;
    side: string;
    symbol: SymbolPair;
    status: string[];
    searchParams?: {
        values: string[];
        type: string;
    };
}

export type ExchangeChartData = {
    [key: string]: {
        Date: string;
        Close: number;
    };
};

export interface IBenchmarkIntervalMap {
    [key: string]: {
        BestBid?: SeriesItem;
        BastAsk?: SeriesItem;
        MidPrice?: SeriesItem;
    };
}

export interface ISymbolIntervalMap {
    [key: string]: IChartInterval[];
}

export interface ISymbolLegendConfigMap {
    [key: string]: LegendConfig;
}

export type MIChartRequestBody = {
    period: Period;
    fromTime: number;
    toTime: number;
    symbolsPair: ISymbolsPair[];
    exchanges?: string[];
    searchParams?: IEventsSearchParams;
    tableSearchParams?: ISearchQueryParams;
    saveLastState?: boolean; // In the backend by default if nothing is sent the server saves the last state. So by default the value is true.
    isZoomIn?: boolean;
    isDerivatives?: boolean;
    candleView?: boolean;
    saveTimeRange?: boolean;
    searchCriteria?: ISearchCriteria; // search criteria V3
};

export type MIChartRequestBodyV2 = {
    period: Period;
    fromTime: number;
    toTime: number;
    symbolsPair: ISymbolsPair[];
    exchange: string[];
    searchParams?: IEventsSearchParams;
};

export interface IPriceChartInfo {
    displayBbo: boolean;
    displayMidPrice: boolean;
    dataSourceExchange: string;
    dataSourceSymbol?: string;
    bestAskColor: string;
    bestBidColor: string;
    midPriceColor: string;
}

export type SeriesItem = {
    [key: string]: {
        Date: string;
        Close: number;
    };
};

export interface IntervalsMap {
    [key: string]: IChartInterval;
}

export type ExchangeSymbolsMap = { [key: number]: string[] };

export interface LegendModel {
    [key: string]: LegendItem;
}

export interface LegendItem {
    key: string;
    price: number;
    isHide: boolean;
    exchange: string;
    symbol: string;
    config: LegendConfig;
}

export interface LegendConfig {
    color: string;
    lineWidth: number;
    lineType: string;
    index?: number;
}

export interface ILegendConfig {
    key: string;
    config: LegendConfig;
}

// Events
export interface IChartEventsResponse {
    sellExecutions?: IChartExecution[];
    buyExecutions?: IChartExecution[];
}

export interface IChartExecution {
    id: string;
    symbol?: string;
    startDate: string;
    endDate?: string;
    status?: string;
    price: number;
    version?: number;
    versions?: IChartExecution[];
    quantity?: number;
}

export type MIChartFilter = {
    period: TimePeriod;
    range: TimeRange;
    symbolsPair: string[];
    exchanges: string[];
};

export type FilterChange = {
    filter: MIChartFilter;
    triggerGetData: boolean;
    isInitChange: boolean;
    isCustomRange: boolean;
    isZoomIn?: boolean;
    switchView?: boolean;
    isDerivatives?: boolean;
};

export type CompereSymbolChange = { symbolPair: string; remove: boolean };

export type TimeRange = {
    fromTime: number;
    toTime: number;
};

export interface RowColorRule {
    [key: string]: string;
}

export interface ICreatePerformanceMarker {
    onClickCallback?: (any?) => void;
    headline?: string;
    description: string[];
    date: Date;
    price: number;
    label: string;
    form: MarkerForm;
    category: string;
    color: string;
    displayStem: boolean;
    displayCategory: boolean;
    symbol: string;
    status: LegendStatus | string;
    clickSpecification: ClickSpecification;
    image?: ICreateImageDto;
}

export interface ICreateSimplePerformanceMarker<T> {
    onClickCallback?: (any?) => void;
    date: Date;
    price: number;
    label: string;
    category: string;
    color: string;
    markerType: SimplePerformanceMarkerType;
    headline?: string;
    description: string[];
    config: T;
}

export enum SimplePerformanceMarkerType {
    tradeMarker
}

export interface ICreateMarker {
    onClickCallback?: (any?) => void;
    description: string[];
    date: Date;
    price: number;
    label: string;
    type: MarkerType;
    classList: MarkerClassType[];
    markerId?: string;
}

export enum OrderStatus {
    partiallyfilled = 'partiallyfilled',
    cancelled = 'cancelled',
    canceled = 'canceled',
    filled = 'filled',
    new = 'new',
    rejected = 'rejected',
    expired = 'expired',
    canceledfill = 'canceledfill',
    csvUndefinedStatus = 'csvundefinedstatus',
    replaced = 'replaced'
}

export interface IChartPaginationResponse {
    chartData?: IChartInterval[];
    eventsStatistic?: MiEventsIntervalStatisticResponse;
    hasAlertsData?: boolean;
    isEventsTotalExceedsTheLimit?: boolean;
}

export type SideBarButton = {
    type: SidebarPanelType;
    tooltip: string;
    svgIcon: string;
    disabled?: boolean;
    autoId?: string;
};

export const TOGGLE_ORDERBOOK_PANEL = 'Toggle Order Book panel';
export const TOGGLE_ALERT_DESCRIPTION_PANEL = 'Toggle Alert Description panel';
export const TOGGLE_GENERAL_SIDEBAR_MENU = 'Toggle Menu';
export const TOGGLE_NEWS = 'News';
export const TOGGLE_TRADE_HISTORY = 'All Trades';

export const SideBarButtonMap: { [key: string]: SideBarButton } = {
    [SidebarPanelType.ORDERBOOK]: {
        type: SidebarPanelType.ORDERBOOK,
        tooltip: TOGGLE_ORDERBOOK_PANEL,
        svgIcon: 'compare_arrows',
        autoId: 'btn_OrderBookPanel'
    },
    [SidebarPanelType.DESCRIPTION]: {
        type: SidebarPanelType.DESCRIPTION,
        tooltip: TOGGLE_ALERT_DESCRIPTION_PANEL,
        svgIcon: 'description',
        autoId: 'btn_AlertDescriptionPanel'
    },
    [SidebarPanelType.CLIENT_DATA_VIEW]: {
        type: SidebarPanelType.CLIENT_DATA_VIEW,
        tooltip: 'Client Analytics',
        svgIcon: 'client_data_view_panel_icon',
        autoId: 'btn_ClientDataViewPanel'
    },
    [SidebarPanelType.TRADE_HISTORY]: {
        type: SidebarPanelType.TRADE_HISTORY,
        tooltip: TOGGLE_TRADE_HISTORY,
        svgIcon: 'trade_history',
        autoId: 'btn_TradeHistoryPanel'
    },
    [SidebarPanelType.NEWS]: {
        type: SidebarPanelType.NEWS,
        tooltip: TOGGLE_NEWS,
        svgIcon: 'news-panel',
        autoId: 'btn_NewsPanel'
    }
};

export const TableTypeMap: { [key in MITableType]: UserSettingsKeys } = {
    [MITableType.EVENT]: UserSettingsKeys.AG_MARKET_INSPECTION_EVENTS_COLUMNS_CONFIGURATION,
    [MITableType.ALERT]: UserSettingsKeys.AG_MARKET_INSPECTION_ALERTS_COLUMNS_CONFIGURATION
};

export interface IMiPageTableFetchOptions {
    updateClientIds?: boolean;
    skipTotalNumberUpdate?: boolean;
    skipFetchTotalCheck?: boolean;
}

export interface IMiFetchDataOptions {
    blockEventStatisticQuery?: boolean;
    blockAlertsRequest?: boolean;
    blockTotalCheck?: boolean;
}

export interface CandleSizeChange {
    newCandleWidthPx: number;
    updateMarkers: boolean;
}
